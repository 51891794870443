import loadLocalStorage from './actions/loadLocalStorage';
import mergeRemoteCharacters from './actions/mergeRemoteCharacters';
import createCharacter from './actions/createCharacter';
import deleteRemoteCharacters from './actions/deleteRemoteCharacters';
import { initialState as initialUIState } from '../UI/reducers/index';
import { initialState as initialUserState } from '../User/reducer';
import arbitrageUserImpersonation from './actions/arbitrageUserImpersonation';
import arbitrageUserUpdate from './actions/arbitrageUserUpdate';
import revertableCharacterData from './actions/revertableCharacterData';
import hashifyExtraBuilds from './actions/hashifyExtraBuilds';
import buildPayload from '../Character/actions/buildPayload';

const currentDataVersion = 1;
let newCharacterID;
let newCharacterData;
let newStorage;
let newUser;
const initialState = {
  currentCharacterID: undefined,
  characterStorage: undefined,
  okToWrite: false,
  user: initialUserState,
  ui: initialUIState,
  currentDataVersion,
};

const anyCharacter = (state, uuid, patch) => ({
  ...state,
  characterStorage: {
    ...state.characterStorage,
    ...{
      [uuid]: {
        ...state.characterStorage[uuid],
        ...patch,
      },
    },
  },
});

const selectedCharacter = (state, patch) => ({
  ...state,
  characterStorage: {
    ...state.characterStorage,
    ...{
      [state.currentCharacterID]: {
        ...state.characterStorage[state.currentCharacterID],
        ...patch,
      },
    },
  },
});

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'APP_LOADED':
      return {
        ...loadLocalStorage(currentDataVersion),
        currentDataVersion,
      };
    case 'FETCH_REMOTE_METADATA':
      ({ newStorage, newCharacterID } = mergeRemoteCharacters(
        payload.data.characters,
        state.characterStorage,
        state.currentCharacterID,
        payload.tokenInitiation,
        payload.isImpersonating,
        payload.data.id,
      ));
      newUser = arbitrageUserImpersonation({ state, payload });

      return {
        ...state,
        currentCharacterID: newCharacterID,
        characterStorage: newStorage,
        user: newUser,
      };
    case 'USER_IMPERSONATE_ERROR':
      return {
        ...state,
        user: {
          ...state.user,
          impersonatee: {
            isError: true,
            id: payload.data.id,
          },
        },
      };
    case 'TOGGLE_MOD':
      return {
        ...state,
        ui: {
          ...state.ui,
          mods: {
            ...state.ui.mods,
            [payload]:
              state.ui.mods[payload] === 'expanded' ? 'collapsed' : 'expanded',
          },
        },
      };
    case 'TOGGLE_MOD_SECTION':
      return {
        ...state,
        ui: {
          ...state.ui,
          modSections: {
            ...state.ui.modSections,
            [payload]:
              state.ui.modSections[payload] === 'expanded'
                ? 'collapsed'
                : 'expanded',
          },
        },
      };
    case 'EXPAND_MOD_SECTION':
      return {
        ...state,
        ui: {
          ...state.ui,
          modSections: {
            ...state.ui.modSections,
            [payload]: 'expanded',
          },
        },
      };
    case 'UPDATE_SELECTED_BRANCHES':
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedBranches: payload,
        },
      };
    case 'UPDATE_USER_SUCCEEDED':
      return arbitrageUserUpdate({ state, payload });
    case 'ADD_MEMBERSHIP_SUCCEEDED':
      return arbitrageUserUpdate({ state, payload });
    case 'CLEAR_NEW_USER':
      return {
        ...state,
        user: {
          ...state.user,
          newUser: null,
        },
      };
    case 'CLEANUP_PREVIOUS_SESSION':
    case 'NUKE_DUE_TO_TOKEN_EXPIRATION':
    case 'LOGOUT_ALL_SESSIONS':
    case 'DELETE_SESSION':
      ({ newStorage, newCharacterID } = deleteRemoteCharacters({
        currentCharacterID: state.currentCharacterID,
        storage: state.characterStorage,
      }));
      return {
        ...state,
        currentCharacterID: newCharacterID,
        characterStorage: newStorage,
        user: initialUserState,
      };
    case 'SWITCH_CHARACTER':
      return {
        ...state,
        currentCharacterID: payload.id,
        characterStorage: {
          ...state.characterStorage,
          ...{ [payload.id]: payload.newState },
        },
      };
    case 'RENAME_CHARACTER':
      return anyCharacter(state, payload.uuid, { name: payload.name });
    case 'UPDATE_LORE':
      return selectedCharacter(state, {
        lores: {
          ...state.characterStorage[state.currentCharacterID].lores,
          ...payload,
        },
      });
    case 'DEBUG_APPROVED':
      return selectedCharacter(state, { approvedForPlay: true });
    case 'DEBUG_NOT_APPROVED':
      return selectedCharacter(state, { approvedForPlay: false });
    case 'UPDATE_STAT':
      return selectedCharacter(state, {
        stats: {
          ...state.characterStorage[state.currentCharacterID].stats,
          ...payload,
        },
      });
    case 'UPDATE_TOTAL_BUILD':
      return selectedCharacter(state, {
        totalBuild: {
          ...state.characterStorage[state.currentCharacterID].totalBuild,
          ...payload,
        },
      });
    case 'UPDATE_SKILL':
      return selectedCharacter(state, {
        skills: {
          ...state.characterStorage[state.currentCharacterID].skills,
          ...{
            trees: {
              ...state.characterStorage[state.currentCharacterID].skills.trees,
              ...payload,
            },
          },
        },
      });
    case 'UPDATE_SKILL_INFO_VISIBILITY':
      return {
        ...state,
        characterStorage: {
          ...state.characterStorage,
          ...{
            [state.currentCharacterID]: {
              ...state.characterStorage[state.currentCharacterID],
              ...{
                skillInfoVisibility: {
                  ...state.characterStorage[state.currentCharacterID]
                    .skillInfoVisibility,
                  ...payload,
                },
              },
            },
          },
        },
      };
    case 'ADD_FRACTURE_SUCCEEDED':
      return selectedCharacter(state, {
        fractures:
          state.characterStorage[state.currentCharacterID].fractures.concat(
            payload,
          ),
      });
    case 'UPDATE_SKILL_BUILD':
      return selectedCharacter(state, {
        skills: {
          ...state.characterStorage[state.currentCharacterID].skills,
          ...{
            categories: {
              ...state.characterStorage[state.currentCharacterID].skills
                .categories,
              ...payload,
            },
          },
        },
      });
    case 'UPDATE_STRAIN':
      return selectedCharacter(state, { strain: payload });
    case 'UPDATE_FAITH':
      return selectedCharacter(state, { faith: payload });
    case 'UPDATE_LEGACY_HOME_GAME_COUNT':
      return selectedCharacter(state, { legacyHomeGames: payload });
    case 'UPDATE_CHARACTER_PLAYABLE_STATE':
      return selectedCharacter(state, { remoteStatus: payload });
    case 'UPDATE_CHARACTER_RETIREMENT_BUILD':
      return selectedCharacter(state, {
        retirementBuild: payload.retirementBuild,
        futureAttendances: payload.futureAttendances,
      });
    case 'REVERT_TO_CHECKPOINT':
      return {
        ...state,
        characterStorage: {
          ...state.characterStorage,
          [state.currentCharacterID]: {
            ...state.characterStorage[state.currentCharacterID],
            ...state.characterStorage[state.currentCharacterID].dirtyTracking,
          },
        },
      };
    case 'UPDATE_CHARACTER_FROM_REMOTE':
      return {
        ...state,
        characterStorage: {
          ...state.characterStorage,
          [state.currentCharacterID]: {
            ...state.characterStorage[state.currentCharacterID],
            ...revertableCharacterData({ state, payload }),
            homeGameAttendances: payload.homeGameAttendances,
            extraBuilds: hashifyExtraBuilds(payload.characterExtraBuilds),
            homeGamesAttended: payload.homeGamesAttended,
            legacyHomeGames: payload.legacyHomeGames,
            legacyHomeGamesPersisted: payload.legacyHomeGames,
            travelGameAttendances: payload.travelGameAttendances,
            remotePersistance: payload.remotePersistance,
            remotePlayerID: payload.remotePlayerID,
            remoteStatus: payload.remoteStatus,
            approvedForPlay: payload.approvedForPlay,
            fractures: payload.fractures,
            notes: payload.notes,
            playerNotes: payload.playerNotes,
            createdAt: payload.createdAt,
            legacyDatum: payload.legacyDatum,
            // dirtyTracking: revertableCharacterData({ state, payload }),
          },
        },
      };
    case 'UPDATE_CHARACTER_DIRTY_TRACKING':
      return selectedCharacter(state, {
        dirtyTracking: buildPayload(
          state.characterStorage[state.currentCharacterID],
        ),
      });
    case 'CREATE_CHARACTER':
      ({ newCharacterID, newCharacterData } = createCharacter());
      return {
        ...state,
        currentCharacterID: newCharacterID,
        characterStorage: {
          ...state.characterStorage,
          ...{ [newCharacterID]: newCharacterData },
        },
      };
    case 'DELETE_CHARACTER':
      return anyCharacter(state, payload, { state: 'deleted' });
    case 'UNDELETE_CHARACTER':
      return anyCharacter(state, payload, { state: 'enabled' });
    case 'CREATE_SESSION_SUCCESS':
      return {
        ...state,
        okToWrite: true,
        user: {
          ...state.user,
          id: payload.user.id,
          name: [payload.user.first_name, payload.user.last_name]
            .join(' ')
            .trim(),
          emailAddress: payload.user.email_address,
          branchId: payload.user.branch.id,
          impersonatee: {},
          session: {
            headers: {
              'X-Auth-Token': payload.token,
            },
            initiation: Date.now(), // used to detect 10s window
            expiration: Date.parse(payload.expires_at),
          },
        },
      };
    default:
      return state;
  }
};
