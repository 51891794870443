import uuid from 'uuid';
import { cloneDeep } from 'lodash';
import { initialState } from '../../Character/reducers/index';
import cleanUpOrphanedRemoteCharacters from './cleanUpOrphanedRemoteCharacters';

export default (
  remoteCharacters,
  characterStorage,
  currentCharacterID,
  tokenInitiation,
  isImpersonating,
  impersonateeID,
) => {
  const newStorage = cloneDeep(characterStorage);
  let newCharacterID = currentCharacterID;
  const syncMetadata = (remoteID, remoteData) => {
    if (!newStorage[remoteID]) return;
    newStorage[remoteID].name = remoteData.name;
    newStorage[remoteID].totalBuild = {
      earned: remoteData.build_earned,
      used: remoteData.build_used,
    };
    newStorage[remoteID].remotePlayerID = remoteData.user_id;
    newStorage[remoteID].remoteStatus = remoteData.status;
  };
  const generateCharacterTemplate = (remoteID, remoteData) => {
    newStorage[uuid.v1()] = {
      ...initialState,
      ...{
        name: remoteData.name,
        totalBuild: {
          earned: remoteData.build_earned,
          used: remoteData.build_used,
        },
        remotePlayerID: remoteData.user_id,
        remoteStatus: remoteData.status,
        remoteID,
      },
    };
  };
  const indexRemoteCharacters = () => {
    const t = {};
    Object.keys(characterStorage)
      .filter(csid => characterStorage[csid].remoteID !== null)
      .forEach(csid => {
        t[characterStorage[csid].remoteID] = csid;
      });
    return t;
  };
  const cleanUpUnrelatedImpersonatedCharacters = storage =>
    Object.keys(storage)
      .filter(x => storage[x].remoteID !== null)
      .filter(x => storage[x].remotePlayerID !== impersonateeID)
      .forEach(uuid => delete storage[uuid]);

  const mergedRemoteCharacters = indexRemoteCharacters();
  cleanUpOrphanedRemoteCharacters(mergedRemoteCharacters, newStorage);
  cleanUpUnrelatedImpersonatedCharacters(newStorage);

  remoteCharacters.reverse().forEach(remoteCharacter => {
    const remoteID = remoteCharacter.id;
    if (remoteID in mergedRemoteCharacters) {
      syncMetadata(mergedRemoteCharacters[remoteID], remoteCharacter);
    } else {
      generateCharacterTemplate(remoteID, remoteCharacter);
    }
  });

  if (Date.now() - tokenInitiation < 10000) {
    if (newStorage[newCharacterID].remoteID === null) {
      const maybeOnlineCharacter = Object.keys(newStorage)
        .filter(x => newStorage[x].remoteID !== null)
        .map(csid => csid)[0];
      newCharacterID = maybeOnlineCharacter || newCharacterID;
    }
  }

  newCharacterID = Object.keys(newStorage)
    .filter(x => newStorage[x].remoteID !== null)
    .filter(x => ['active', 'staged'].includes(newStorage[x].remoteStatus))
    .sort(
      (a, b) =>
        newStorage[b].totalBuild.earned - newStorage[a].totalBuild.earned,
    )[0];
  if (!(newCharacterID in newStorage)) {
    newCharacterID = Object.keys(newStorage)[0];
  }
  return { newStorage, newCharacterID };
};
