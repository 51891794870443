import {
  all,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import axios from 'axios';
import { toLocalFaith } from './actions/transformFaith';
import { toLocalLores } from './actions/transformLores';
import { toLocalSkills } from './actions/transformSkills';
import { toLocalStrain, strainInnateStats } from './actions/transformStrain';
import { hasValidToken } from '../utils/token';
import { updateUpstream } from '../utils/remote';
import computePlanDelta from './actions/computePlanDelta';
import combinePlanSigma from './actions/combinePlanSigma';
import { initialState as initialRemotePersistance } from './reducers/remotePersistance';
import { isPrivileged } from '../utils/user';
import { toRemoteExtraBuild } from './actions/transformExtraBuild';
import constructPayload from './actions/buildPayload';
import fetchUser from '../User/actions/fetchUser';
import Toast from '../Shared/Toastify/Toast';

const api = id => `/characters/${id}`;
const throttleDelay = 500; // ms

function* buildPayload(isRevert = false) {
  const character = yield select(state => state.character);
  return isRevert ? character.dirtyTracking : constructPayload(character);
}

function* fetchCharacter(remoteID) {
  const user = yield select(state => state.user);
  const authConfig = user.session;
  const isAdminAccess = isPrivileged(user);
  if (!authConfig || !remoteID) return;

  try {
    yield put({ type: 'FETCH_BEGIN' });
    const remoteData = yield axios.get(api(remoteID), authConfig);

    const draftData =
      remoteData.data.drafts &&
      !isAdminAccess &&
      remoteData.data.status !== 'staged'
        ? JSON.parse(atob(remoteData.data.drafts))
        : null;
    const strain = toLocalStrain(remoteData.data.strain_id);
    const innateStats = strainInnateStats(strain);
    const skillLoreMultiplex = {
      lores: toLocalLores(remoteData.data.lores_id),
      skills: toLocalSkills(remoteData.data.skills_id),
    };
    const isEditable =
      !remoteData.data.approved_at || remoteData.data.status === 'staged';
    const sigma = combinePlanSigma(
      draftData,
      remoteData.data,
      skillLoreMultiplex,
      strain,
    );
    const combinedPlan = {
      lores: sigma.lores,
      skills: sigma.skills,
      body: sigma.stats.body,
      mind: sigma.stats.mind,
      resolve: sigma.stats.resolve,
      infection: sigma.stats.infection,
    };

    const remotePersisted = isEditable
      ? initialRemotePersistance
      : {
          stats: {
            body: remoteData.data.body + innateStats.body,
            mind: remoteData.data.mind + innateStats.mind,
            resolve: remoteData.data.resolve + innateStats.resolve,
            infection: remoteData.data.infection + innateStats.infection,
            deaths: remoteData.data.deaths,
          },
          skills: skillLoreMultiplex.skills,
          lores: skillLoreMultiplex.lores,
          buildUsed: remoteData.data.build_used,
        };

    yield put({
      type: 'UPDATE_CHARACTER_FROM_REMOTE',
      payload: {
        strain,
        body: combinedPlan.body,
        mind: combinedPlan.mind,
        resolve: combinedPlan.resolve,
        infection: combinedPlan.infection,
        deaths: remoteData.data.deaths,
        earnedXP: remoteData.data.build_earned,
        usedXP: remoteData.data.build_used,
        name: remoteData.data.name,
        faith: toLocalFaith(remoteData.data.faith_id),
        skills: combinedPlan.skills,
        lores: combinedPlan.lores,
        fractures: remoteData.data.fractures,
        remotePersistance: remotePersisted,
        approvedForPlay: !isEditable,
        remotePlayerID: remoteData.data.user_id,
        remoteStatus: remoteData.data.status,
        notes: remoteData.data.notes || '',
        playerNotes: remoteData.data.player_notes || '',
        homeGameAttendances: remoteData.data.home_game_attendances,
        travelGameAttendances: remoteData.data.travel_game_attendances,
        homeGamesAttended: remoteData.data.home_game_attendances.length,
        legacyHomeGames: remoteData.data.legacy_home_games,
        characterExtraBuilds: remoteData.data.character_extra_builds,
        createdAt: remoteData.data.created_at,
        legacyDatum: remoteData.data.legacy_datum || {},
      },
    });
    yield put({ type: 'UPDATE_CHARACTER_DIRTY_TRACKING' });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  } finally {
    yield put({ type: 'FETCH_COMPLETE' });
  }
}

function* fetchCharacterRetirementBuild({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const remoteID = payload;

  try {
    const res = yield axios.get(
      `/characters/${remoteID}/retirement_build`,
      authConfig,
    );
    yield put({
      type: 'UPDATE_CHARACTER_RETIREMENT_BUILD',
      payload: {
        retirementBuild: res.data.retirement_build,
        futureAttendances: res.data.future_attendances,
      },
    });
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }
}

function* fetchSwitchedCharacter({ payload }) {
  yield fetchCharacter(payload.newState.remoteID);
}

function* fetchCurrentCharacter() {
  const remoteID = yield select(state => state.character.remoteID);
  yield fetchCharacter(remoteID);
}

function* fetchByEagerLoad({ payload }) {
  yield fetchCharacter(payload);
}

function* markInProgress() {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);
  if (remoteID && hasValidToken(authConfig))
    yield put({ type: 'SAVE_UPSTREAM_BEGIN' });
}

function* markAnyInProgress({ payload }) {
  const authConfig = yield select(state => state.user.session);
  if (payload.remoteID && hasValidToken(authConfig))
    yield put({ type: 'SAVE_UPSTREAM_BEGIN' });
}

function* pushRenameCharacter({ payload }) {
  const { remoteID } = payload;
  const authConfig = yield select(state => state.user.session);
  const generator = function* () {
    yield axios.put(api(remoteID), { name: payload.name }, authConfig);
  };
  yield updateUpstream(remoteID, generator);
}

function* pushUpdateNotes({ payload }) {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);
  const generator = function* () {
    yield axios.put(api(remoteID), { notes: payload }, authConfig);
  };
  yield updateUpstream(remoteID, generator, 'Staff Notes updated');
}

function* pushUpdatePlayerNotes({ payload }) {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);
  const generator = function* () {
    yield axios.put(api(remoteID), { player_notes: payload }, authConfig);
  };
  yield updateUpstream(remoteID, generator, 'Player Notes updated');
}

function* pushLegacyHomeGameCount({ payload }) {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);
  const generator = function* () {
    yield axios.patch(
      api(remoteID),
      { legacy_home_games: payload },
      authConfig,
    );
  };
  yield updateUpstream(remoteID, generator);
  yield fetchCharacter(remoteID);
}

function* pushExtraBuilds({ payload }) {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);
  const upstreamPayload = toRemoteExtraBuild(payload);

  if (upstreamPayload.length === 0) return;

  const generator = function* () {
    yield axios.patch(
      api(remoteID),
      { character_extra_builds: upstreamPayload },
      authConfig,
    );
  };
  yield updateUpstream(remoteID, generator);
  yield fetchCharacter(remoteID);
}

function* retireCharacter({ payload }) {
  const user = yield select(state => state.user);
  const authConfig = user.session;

  try {
    yield axios.post(`/characters/${payload}/retire`, null, authConfig);
  } catch (error) {
    Toast({ text: error.response.data.message, type: 'error' });
  }

  yield fetchUser(authConfig, {
    id: user.impersonatee.id || user.id,
    isImpersonating: user.impersonatee.id,
  });
}

function* pushChanges({ type }) {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);
  const isRevert = type === 'REVERT_TO_CHECKPOINT';
  const toastVerb = isRevert ? 'reverted' : 'updated';
  const toastID = isRevert
    ? 'character-revert-success'
    : 'character-update-success';
  const generator = function* () {
    try {
      const upstreamData = yield buildPayload(isRevert);
      if (Object.keys(upstreamData).length === 0) return;
      yield axios.put(api(remoteID), upstreamData, authConfig);
      Toast({
        text: `Character ${toastVerb} successfully`,
        type: 'success',
        toastID,
      });
    } catch (error) {
      Toast({
        text: error.response.data.message,
        type: 'error',
      });
    }
  };
  yield updateUpstream(remoteID, generator);
  if (isRevert) yield fetchCharacter(remoteID);
}

function* pushPlayableStateChange({ payload }) {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);

  const generator = function* () {
    yield axios.patch(api(remoteID), { status: payload }, authConfig);
    Toast({
      text: `Character status successfully updated to ${payload}`,
      type: 'success',
    });
  };
  yield updateUpstream(remoteID, generator);
}

function* buildDraft() {
  const persistedSkills = yield select(
    state => state.character.remotePersistance.skills,
  );
  const plannedSkills = yield select(state => state.character.skills.trees);
  const plannedStats = yield select(state => state.character.stats);
  const persistedLores = yield select(
    state => state.character.remotePersistance.lores,
  );
  const plannedLores = yield select(state => state.character.lores);

  const y = computePlanDelta({
    persistedSkills,
    plannedSkills,
    plannedStats,
    persistedLores,
    plannedLores,
  });
  const b64 = btoa(JSON.stringify(y));
  const rev = JSON.parse(atob(b64));
  console.log(y); // eslint-disable-line no-console
  console.log(b64); // eslint-disable-line no-console
  console.log(rev); // eslint-disable-line no-console

  return { drafts: b64 };
}

function* pushDraft() {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);
  const generator = function* () {
    const upstreamDraft = yield buildDraft();
    yield axios.put(api(remoteID), upstreamDraft, authConfig);
    Toast({
      text: 'Character Draft saved successfully',
      type: 'success',
      toastID: 'character-draft-update-success',
    });
  };
  yield updateUpstream(remoteID, generator);
}

function* createCharacter({ payload }) {
  const authConfig = yield select(state => state.user.session);
  const user = yield select(state => state.user);

  if (hasValidToken(authConfig)) {
    try {
      yield axios.post('/characters', payload.changes, authConfig);
      if (user.impersonatee.id) {
        yield put({
          type: 'IMPERSONATE_USER',
          payload: {
            userID: user.impersonatee.id,
            isImpersonating: true,
          },
        });
      } else {
        yield put({
          type: 'IMPERSONATE_USER',
          payload: {
            userID: user.id,
            isImpersonating: false,
          },
        });
      }
      yield put({ type: 'TOGGLE_POPPER', payload: 'characterSelector' });
    } catch (error) {
      yield put({
        type: 'UPDATE_MODAL_ERROR',
        payload: error.response.data.message,
      });
    }
  } else if (authConfig && !hasValidToken(authConfig)) {
    yield put({ type: 'DELETE_SESSION' });
    yield put({ type: 'NUKE_DUE_TO_TOKEN_EXPIRATION' });
  }
}

function* addFracture({ payload }) {
  const authConfig = yield select(state => state.user.session);

  if (hasValidToken(authConfig)) {
    try {
      const characterId = yield select(state => state.character.remoteID);
      const res = yield axios.post(
        `/characters/${characterId}/fractures`,
        {
          name: payload.name,
        },
        authConfig,
      );
      yield put({
        type: 'ADD_FRACTURE_SUCCEEDED',
        payload: res.data,
      });
    } catch (error) {
      yield put({
        type: 'UPDATE_MODAL_ERROR',
        payload: error.response.data.message,
      });
    }
  }
}

function* updateFracture({ payload }) {
  const authConfig = yield select(state => state.user.session);

  if (hasValidToken(authConfig)) {
    try {
      const characterId = yield select(state => state.character.remoteID);
      const res = yield axios.patch(
        `/characters/${characterId}/fractures/${payload.id}`,
        {
          name: payload.name,
        },
        authConfig,
      );
      Toast({
        text: `Fracture successfully renamed to ${payload.name}`,
        type: 'success',
      });
      yield put({
        type: 'UPDATE_FRACTURE_SUCCEEDED',
        payload,
      });
    } catch (error) {
      yield put({
        type: 'UPDATE_MODAL_ERROR',
        payload: error.response.data.message,
      });
    }
  }
}

function* toggleFracture({ payload }) {
  const authConfig = yield select(state => state.user.session);

  if (hasValidToken(authConfig)) {
    try {
      const characterId = yield select(state => state.character.remoteID);
      const res = yield axios.post(
        `/characters/${characterId}/fractures/${payload.id}/${payload.value}`,
        {},
        authConfig,
      );
      yield put({
        type: 'TOGGLE_FRACTURE_SUCCEEDED',
        payload: res.data,
      });
    } catch (error) {
      yield put({
        type: 'UPDATE_MODAL_ERROR',
        payload: error.response.data.message,
      });
    }
  }
}

function* undraftCharacter() {
  const remoteID = yield select(state => state.character.remoteID);
  const authConfig = yield select(state => state.user.session);
  const undraft = { drafts: null };

  yield axios.put(api(remoteID), undraft, authConfig);
}

function* watchAddFracture() {
  yield takeLatest('ADD_FRACTURE', addFracture);
}

function* watchUpdateFracture() {
  yield takeLatest('UPDATE_FRACTURE', updateFracture);
}

function* watchToggleFracture() {
  yield takeLatest('TOGGLE_FRACTURE', toggleFracture);
}

function* watchCreateSession() {
  yield takeLatest('CREATE_SESSION', fetchCurrentCharacter);
}

function* watchSwitchCharacter() {
  yield takeLatest('SWITCH_CHARACTER', fetchSwitchedCharacter);
}

function* watchFetchRemoteCharacter() {
  yield throttle(
    throttleDelay,
    'FETCH_REMOTE_CHARACTER',
    fetchCurrentCharacter,
  );
}

function* watchFetchCharacterRetirementBuild() {
  yield takeLatest(
    'FETCH_CHARACTER_RETIREMENT_BUILD',
    fetchCharacterRetirementBuild,
  );
}

function* watchUpdateFaith() {
  yield takeEvery('UPDATE_FAITH', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_FAITH', pushChanges);
}

function* watchUpdateLoreUpstream() {
  yield takeEvery('UPDATE_LORE_UPSTREAM', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_LORE_UPSTREAM', pushChanges);
}

function* watchUpdateNotes() {
  yield takeEvery('UPDATE_NOTES', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_NOTES', pushUpdateNotes);
}

function* watchUpdatePlayerNotes() {
  yield takeEvery('UPDATE_PLAYER_NOTES', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_PLAYER_NOTES', pushUpdatePlayerNotes);
}

function* watchUpdateSkillUpstream() {
  yield takeEvery('UPDATE_SKILL_UPSTREAM', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_SKILL_UPSTREAM', pushChanges);
}

function* watchUpdateStrain() {
  yield takeEvery('UPDATE_STRAIN', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_STRAIN', pushChanges);
}

function* watchUpdateStatUpstream() {
  yield takeEvery('UPDATE_STAT_UPSTREAM', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_STAT_UPSTREAM', pushChanges);
}

function* watchUpdatePlayableState() {
  yield takeEvery('UPDATE_CHARACTER_PLAYABLE_STATE', markInProgress);
  yield debounce(
    throttleDelay,
    'UPDATE_CHARACTER_PLAYABLE_STATE',
    pushPlayableStateChange,
  );
}

function* watchDraftUpdateStat() {
  yield takeEvery('UPDATE_STAT_DRAFTS', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_STAT_DRAFTS', pushDraft);
}

function* watchDraftUpdateSkill() {
  yield takeEvery('UPDATE_SKILL_DRAFTS', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_SKILL_DRAFTS', pushDraft);
}

function* watchDraftUpdateLore() {
  yield takeEvery('UPDATE_LORE_DRAFTS', markInProgress);
  yield debounce(throttleDelay, 'UPDATE_LORE_DRAFTS', pushDraft);
}

function* watchRevertToCheckPoint() {
  yield takeLatest('REVERT_TO_CHECKPOINT', pushChanges);
  // yield debounce(throttleDelay, 'REVERT_TO_CHECKPOINT', pushChanges);
}

function* watchRetrySaveUpstream() {
  yield takeEvery('RETRY_SAVE_UPSTREAM', markInProgress);
  yield throttle(throttleDelay, 'RETRY_SAVE_UPSTREAM', pushChanges);
}

function* watchRenameCharacter() {
  yield takeEvery('RENAME_CHARACTER', markAnyInProgress);
  yield debounce(throttleDelay, 'RENAME_CHARACTER', pushRenameCharacter);
}

function* watchUpdateLegacyHomeGameCountUpstream() {
  yield takeLatest(
    'UPDATE_LEGACY_HOME_GAME_COUNT_UPSTREAM',
    pushLegacyHomeGameCount,
  );
}

function* watchUpdateCharacterExtraBuilds() {
  yield takeLatest('UPDATE_CHARACTER_EXTRA_BUILDS', pushExtraBuilds);
}

function* watchRetireCharacter() {
  yield takeLatest('RETIRE_CHARACTER', retireCharacter);
}

function* watchDebugUndraft() {
  yield takeLatest('DEBUG_UNDRAFT', undraftCharacter);
}

function* watchEagerLoadRemoteCharacter() {
  yield throttle(
    throttleDelay,
    'EAGER_LOAD_REMOTE_CHARACTER',
    fetchByEagerLoad,
  );
}

function* watchCreateCharacterRemote() {
  yield takeLatest('CREATE_CHARACTER_REMOTE', createCharacter);
}

export default function* () {
  yield all([
    watchCreateSession(),
    watchSwitchCharacter(),
    watchFetchRemoteCharacter(),
    watchFetchCharacterRetirementBuild(),
    watchRetireCharacter(),
    watchUpdateCharacterExtraBuilds(),
    watchUpdateFaith(),
    watchUpdateLoreUpstream(),
    watchUpdateNotes(),
    watchUpdatePlayerNotes(),
    watchUpdateSkillUpstream(),
    watchUpdatePlayableState(),
    watchUpdateStrain(),
    watchUpdateStatUpstream(),
    watchAddFracture(),
    watchUpdateFracture(),
    watchToggleFracture(),
    watchRevertToCheckPoint(),
    watchDraftUpdateStat(),
    watchDraftUpdateSkill(),
    watchDraftUpdateLore(),
    watchDebugUndraft(),
    watchRenameCharacter(),
    watchEagerLoadRemoteCharacter(),
    watchUpdateLegacyHomeGameCountUpstream(),
    watchRetrySaveUpstream(),
    watchCreateCharacterRemote(),
  ]);
}
