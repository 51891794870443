import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import Loader from 'react-spinners/RingLoader';
import LoadingOverlay from 'react-loading-overlay';
import CharacterLoader from '../utils/loader';
import StaffNotes from './StaffNotes/Container';
import PlayerNotes from './PlayerNotes/Container';
import Fractures from './Fractures';
import Divider from './Divider/Container';
import StrainSelect from './Strain/Select';
import TotalBuild from './TotalBuild/Bar';
import StatBar from './Stat/Bar';
import SkillBar from './Skill/Bar/Component';
import WindowedScrollbar from '../Shared/WindowedScrollbar';
import SkillGrid from './Skill/Grid/Component';
import FaithSelect from './Faith/Select';
import LoreComponent from './Lores/Component';
import UserEditor from './UserEditor/Page';
import history from '../history';
import styles from './Page.module.scss';
import './Page.scss';

const Container = ({ isCheckout }) => {
  const dispatch = useDispatch();
  const {
    currentCharacterID,
    characterStorage,
    isFetchingCharacter,
    ui,
    user,
  } = useSelector(state => ({
    currentCharacterID: state.localStorage.currentCharacterID,
    characterStorage: state.localStorage.characterStorage,
    isFetchingCharacter: state.ui.remote.isFetchingCharacter,
    ui: state.localStorage.ui,
    user: state.user,
  }));

  const eventID = history.location.pathname.match(/checkout\/(\d+)$/);
  const eventIDMatch = eventID ? eventID[1] : null;
  const playerID = history.location.search.match(/\?player_id=(\d+)$/);
  const playerIDMatch = playerID ? parseInt(playerID[1], 10) : null;

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  useEffect(() => {
    CharacterLoader({ characterStorage, currentCharacterID, dispatch });
  }, [characterStorage, currentCharacterID, dispatch]);

  useEffect(() => {
    if (ui) dispatch({ type: 'SYNC_UI', payload: ui });
  }, [ui, dispatch]);

  useEffect(() => {
    if (!user.session || !user.session.headers) return;
    if (!playerIDMatch) return;

    dispatch({
      type: 'IMPERSONATE_USER',
      payload: {
        userID: playerIDMatch,
        isImpersonating: true,
      },
    });
  }, [dispatch, user.session, playerIDMatch]);

  useEffect(() => {
    if (!isCheckout) return;
    if (!user.session || !user.session.headers) return;
    if (!eventIDMatch) return;

    dispatch({
      type: 'FETCH_EVENT_ATTENDEES',
      payload: { eventID: eventIDMatch },
    });
  }, [dispatch, user.session, eventIDMatch, isCheckout]);

  // FOR DEBUG: automatically show user editor on load
  // useEffect(() => {
  //   if (!user.id) return;
  //   dispatch({ type: '_DEBUG_FORCE_OPEN_EDITOR', payload: user.id });
  // }, [dispatch, user.id]);

  return (
    <LoadingOverlay
      active={isFetchingCharacter}
      spinner={<Loader color='#ccc' size={60} />}
      fadeSpeed={100}
      text='Fetching Character...'
    >
      <div className={styles.builder}>
        <div className={styles.container}>
          <Transition in timeout={700} appear>
            {state => (
              <div className={[styles.control, styles[state]].join(' ')}>
                <StrainSelect />
                <TotalBuild />
                <StatBar />
                <SkillBar />
              </div>
            )}
          </Transition>
          <WindowedScrollbar
            styles={styles}
            autoHeightMax='calc(100vh - 168px)'
          >
            <Transition in timeout={700} appear>
              {state => (
                <div className={[styles.megaGroup, styles[state]].join(' ')}>
                  <SkillGrid />
                  <Divider title='Faith & Lores' />
                  <FaithSelect />
                  <LoreComponent />
                  <Fractures divider={<Divider title='Fractures' />} />
                  <PlayerNotes divider={<Divider title='Character Notes' />} />
                  <StaffNotes divider={<Divider title='Staff Notes' />} />
                  <div style={{ height: '64px' }} />
                </div>
              )}
            </Transition>
          </WindowedScrollbar>
        </div>
      </div>
      <Transition timeout={300} in={user.isEditorVisible}>
        {state => (
          <div className={[styles.userEditor, styles[state]].join(' ')}>
            <UserEditor
              user={user}
              isUpdating={user.isUpdating}
              isError={user.isError}
              isSuccessful={user.isSuccessful}
            />
          </div>
        )}
      </Transition>
    </LoadingOverlay>
  );
};

export default React.memo(Container);
